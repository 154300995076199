@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-1 {
    -webkit-text-stroke: 1px #d1d5db;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px #d1d5db;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px #d1d5db;
  }
  
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
